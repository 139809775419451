import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiService } from './api.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class GetTextService {
  private textsSubject = new BehaviorSubject<Record<string, string>>({});

  get valueChanges() {
    return this.textsSubject.asObservable();
  }

  constructor(private apiService: ApiService,
    private storageService: StorageService,
    ) {
    this.storageService.get('accessToken').then((val) => {
      let token = val;
    this.apiService.getText(token).forEach(({ result }) => {
      this.textsSubject.next(result);
    });
  })
  }
}
