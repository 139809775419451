import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import {
  MenuController,
  Platform,
  AlertController,
  ToastController
} from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { buildInfo } from './buildinfo';
import { Market } from '@ionic-native/market/ngx';
import { environment } from '../environments/environment';
import { StorageService } from './services/storage.service';
import { AuthService } from './services/auth.service';
import { LoadingService } from './services/loading.service';
import { ApiService } from './services/api.service';
import { EstimateService } from './services/estimates.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  appPages = [
    {
      title: 'Schedule',
      url: '/app/tabs/schedule',
      icon: 'calendar'
    },
    {
      title: 'Speakers',
      url: '/app/tabs/speakers',
      icon: 'people'
    },
    {
      title: 'Map',
      url: '/app/tabs/map',
      icon: 'map'
    }
  ];
  loggedIn = false;
  dark = false;
  environment = environment;
  loggedInStatus = 'false';
  currentRoutePath: string;

  AppName: string;
  PackageName: string;
  versionCode: string | number;
  versionNumber: string;
  appBuildNumber: string = '';

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private storageService: StorageService,
    private authService: AuthService,
    private apiService: ApiService,
    public loadingService: LoadingService,
    private estimateservice: EstimateService,
    private appVersion: AppVersion,
    private market: Market,
    private alertController: AlertController
  ) {
    this.createStorage();
  }
  async createStorage() {
    await this.storageService.create().then((result) => {
      this.initializeApp();
    });
  }

  async ngOnInit() {
    this.swUpdate.available.subscribe(async (res) => {
      const toast = await this.toastCtrl.create({
        message: '更新があります!',
        position: 'bottom',
        buttons: [
          {
            role: 'cancel',
            text: 'リロード'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  async initializeApp() {
    await this.storageService.get('isLoggedInWithBackend').then((data) => {
      this.loggedInStatus = data;
      if (this.loggedInStatus === 'true') {
        this.authService.changeIsAuthenticated(true);
        console.log('Now we are authenticated');
      } else {
        console.log('NOT LOGGED IN');
      }
    });
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.statusBar.styleDefault();
        this.splashScreen.hide();
        if (this.platform.is('android')) {
          this.statusBar.styleLightContent();
        } else {
          this.statusBar.styleDefault();
        }
        this.appVersion
          .getVersionCode()
          .then((value) => {
            let newValue = value;
            this.versionCode = value;
          })
          .catch((err) => {
            alert(err);
          });
        this.appVersion
          .getVersionNumber()
          .then((value) => {
            this.versionNumber = value;
          })
          .catch((err) => {
            alert(err);
          });
        this.versionNumber = buildInfo.buildVersion.replace(/\./g, '');
        this.apiService
          .checkVersion({ now_version: this.versionNumber })
          .subscribe((result) => {
            if (result.result.is_version_up !== false) {
              if (this.platform.is('ios')) {
                this.updateAppAlert(true, '1568240911');
              } else {
                this.updateAppAlert(true, 'com.app.metalive');
              }
            }
          });
      } else {
        this.versionNumber = buildInfo.buildVersion.replace(/\./g, '');
        this.apiService
          .checkVersion({ now_version: this.versionNumber })
          .subscribe((result) => {
            if (result !== undefined) {
              if (result.result.is_version_up !== false) {
                //this.updateAppAlert(false);
                // PWAのため更新する必要ない
              }
            }
          });
      }
      this.currentRoutePath = this.router.url;
      if (this.loggedInStatus === 'true') {
        this.getClients();
      }
    });
  }

  async getClients() {
    await this.estimateservice.getClients();
  }

  async updateAppAlert(updateStatus, url) {
    const alert = await this.alertController.create({
      cssClass: 'alert-class',
      header: 'アプリの更新',
      message: `アプリの更新があります。ストアで更新してください。`,
      buttons: [
        {
          text: 'OK',
          handler: () => {
            alert.dismiss();
            this.market.open(url); //TODO: Change to correct url
            console.log('Confirm Ok');
          }
        }
      ]
    });
    await alert.present();
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  openTutorial() {
    this.menu.enable(false);
  }
}
