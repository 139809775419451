import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  AUTH_SERVER: string = environment.api_url;
  currentToken: string;

  private issuer = {
    login: `${this.AUTH_SERVER}/login`,
    register: `${this.AUTH_SERVER}/register`
  };

  constructor(
    private storageService: StorageService
    ) {}


//   handleData(token) {
//     this.storageService.set('accessToken', (JSON.stringify(token)).slice(1, -1))
// //    this.storage.set('ACCESS_TOKEN', token);
//   }

  async getToken() {
    await this.storageService.get('accessToken').then((val) => {
      console.log('Your accessToken is', val);
      this.currentToken = val;
      return val;
    });
  }

  // Verify the token
  isValidToken() {

    this.getToken();
    let token = this.currentToken;
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return Object.values(this.issuer).indexOf(payload.iss) > -1
          ? true
          : false;
      }
    } else {
      return false;
    }
  }

  payload(token) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }

  // User state based on valid token
  // isLoggedIn() {
  //   console.log("call isLoggedIn() in Token Service");
  //   return this.isValidToken();
  // }

  // Remove token
  removeToken() {
    this.storageService.remove('auth_token');
    this.storageService.remove('ACCESS_TOKEN');
  }

}
