import { NgModule } from '@angular/core';
import { CheckTutorial } from './providers/check-tutorial.service';

import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { Tab3Page } from './pages/tab3/tab3.page';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { LoginPageModule } from './pages/login/login.module';

const routes: Routes = [
  {
    path: 'thanks/:token',
    loadChildren: () =>
      import('./pages/thanks/thanks.module').then((m) => m.ThanksPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'thanks',
    loadChildren: () =>
      import('./pages/thanks/thanks.module').then((m) => m.ThanksPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'forgot-password/:token',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'tabs',
    loadChildren: () =>
      import('./pages/tabs-page/tabs-page.module').then((m) => m.TabsModule)
    // canLoad: [AuthGuard]
  },
  {
    path: 'chat-tabs',
    loadChildren: () =>
      import('./pages/chat/chat-tabs/chat-tabs.module').then(
        (m) => m.ChatTabsPageModule
      )
  },
  {
    path: 'user-confirm',
    loadChildren: () =>
      import('./pages/user-confirm/user-confirm.module').then(
        (m) => m.UserConfirmPageModule
      )
  },
  {
    path: 'tutorial',
    loadChildren: () =>
      import('./pages/tutorial/tutorial.module').then((m) => m.TutorialModule),
    canLoad: [CheckTutorial]
  },
  // {
  //   path: '',
  //   loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  // },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'static-modal',
    loadChildren: () =>
      import('./pages/modals/static-modal/static-modal.module').then(
        (m) => m.StaticModalPageModule
      )
  },
  {
    path: 'register',
    loadChildren: () =>
      import('./pages/register/register.module').then(
        (m) => m.RegisterPageModule
      )
  },
  {
    path: 'image-modal',
    loadChildren: () =>
      import('./pages/modals/image-modal/image-modal.module').then(
        (m) => m.ImageModalPageModule
      )
  },
  {
    path: 'confirm-actions',
    loadChildren: () =>
      import('./pages/confirm-actions/confirm-actions.module').then(
        (m) => m.ConfirmActionsPageModule
      )
  },
  {
    path: 'clients',
    loadChildren: () =>
      import('./pages/clients/clients.module').then((m) => m.ClientsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./pages/settings/settings.module').then(
        (m) => m.SettingsPageModule
      )
  },
  {
    path: 'details',
    loadChildren: () =>
      import('./pages/settings/details/details.module').then(
        (m) => m.DetailsPageModule
      )
  },
  {
    path: 'estimates',
    children: [
      {
        path: 'create',
        loadChildren: () =>
          import('./pages/estimates/create/create.module').then(
            (m) => m.CreatePageModule
          )
      },
      {
        path: 'share',
        loadChildren: () =>
          import('./pages/estimates/share/share.module').then(
            (m) => m.SharePageModule
          )
      },
      {
        path: 'status-list',
        loadChildren: () =>
          import('./pages/estimates/status-list/status-list.module').then(
            (m) => m.StatusListPageModule
          )
      },
      {
        path: 'list',
        loadChildren: () =>
          import('./pages/estimates/list/list.module').then(
            (m) => m.ListPageModule
          )
      }
    ]
  },
  {
    path: 'share-user-info',
    loadChildren: () =>
      import('./pages/user/share-user-info/share-user-info.module').then(
        (m) => m.ShareUserInfoPageModule
      )
  },
  {
    path: 'market',
    children: [
      {
        path: 'material',
        loadChildren: () =>
          import('./pages/tab4/detail/detail.module').then(
            (m) => m.DetailPageModule
          )
      }
    ]
  },
  {
    path: 'chat',
    children: [
      {
        path: 'message/:id',
        loadChildren: () =>
          import('./pages/chat/message/message.module').then(
            (m) => m.MessagePageModule
          )
      },
      {
        path: 'newgroup',
        loadChildren: () =>
          import('./pages/chat/newgroup/newgroup.module').then(
            (m) => m.NewgroupPageModule
          )
      },
      {
        path: 'userinfo/:id',
        loadChildren: () =>
          import('./pages/chat/userinfo/userinfo.module').then(
            (m) => m.UserinfoPageModule
          )
      },
      {
        path: 'blockedlist',
        loadChildren: () =>
          import('./pages/chat/blockedlist/blockedlist.module').then(
            (m) => m.BlockedlistPageModule
          )
      },
      {
        path: 'addmembers/:id',
        loadChildren: () =>
          import('./pages/chat/addmembers/addmembers.module').then(
            (m) => m.AddmembersPageModule
          )
      },
      {
        path: 'groupinfo/:id',
        loadChildren: () =>
          import('./pages/chat/groupinfo/groupinfo.module').then(
            (m) => m.GroupinfoPageModule
          )
      },
      {
        path: 'group/:id',
        loadChildren: () =>
          import('./pages/chat/group/group.module').then(
            (m) => m.GroupPageModule
          )
      }
    ]
  },
  {
    path: 'company-details',
    loadChildren: () =>
      import('./pages/company/company-details/company-details.module').then(
        (m) => m.CompanyDetailsPageModule
      )
  },
  {
    path: 'info-messages',
    loadChildren: () =>
      import('./pages/info-messages/info-messages.module').then(
        (m) => m.InfoMessagesPageModule
      )
  },
  {
    path: 'user-edit',
    loadChildren: () =>
      import('./pages/user-edit/user-edit.module').then(
        (m) => m.UserEditPageModule
      )
  },
  {
    path: 'purchase',
    loadChildren: () =>
      import('./pages/tab5/purchase/purchase.module').then(
        (m) => m.PurchasePageModule
      )
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
    // },
    // {
    //   path: 'messages',
    //   loadChildren: () => import('./pages/chat/messages/messages.module').then( m => m.MessagesPageModule)
    // },
    // {
    //   path: 'groups',
    //   loadChildren: () => import('./pages/chat/groups/groups.module').then( m => m.GroupsPageModule)
    // },
    // {
    //   path: 'friends',
    //   loadChildren: () => import('./pages/chat/friends/friends.module').then( m => m.FriendsPageModule)
  },
  {
    path: 'share-details',
    loadChildren: () =>
      import('./pages/company/share-details/share-details.module').then(
        (m) => m.ShareDetailsPageModule
      )
  },
  {
    path: 'edit-company',
    loadChildren: () =>
      import('./pages/company/edit-company/edit-company.module').then(
        (m) => m.EditCompanyPageModule
      )
  },
  {
    path: 'group',
    loadChildren: () =>
      import('./pages/clients/group/group.module').then(
        (m) => m.GroupPageModule
      )
  },
  {
    path: 'rank',
    loadChildren: () =>
      import('./pages/clients/rank/rank.module').then((m) => m.RankPageModule)
  },
  {
    path: 'edit-company-settings',
    loadChildren: () =>
      import(
        './pages/company/edit-company-settings/edit-company-settings.module'
      ).then((m) => m.EditCompanySettingsPageModule)
  },
  {
    path: 'qr-code-scanner',
    loadChildren: () =>
      import('./pages/qr-code-scanner/qr-code-scanner.module').then(
        (m) => m.QrCodeScannerPageModule
      )
  },
  {
    path: 'corporate-sharing',
    loadChildren: () =>
      import('./pages/corporate-sharing/corporate-sharing.module').then(
        (m) => m.CorporateSharingPageModule
      )
  },
  {
    path: 'camera',
    loadChildren: () => import('./pages/camera/camera.module').then( m => m.CameraPageModule)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./pages/maintenance/maintenance.module').then( m => m.MaintenancePageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: '404',
    loadChildren: () =>
      import('./pages-error-page/pages-error-page.module').then(
        (m) => m.PagesErrorPagePageModule
      )
  },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
