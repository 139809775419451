import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

//import { IonicStorageModule } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
// import { Drivers } from '@ionic/storage';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConversationPipe } from './pipes/conversation.pipe';
import { DateFormatPipe } from './pipes/date.pipe';
import { FriendPipe } from './pipes/friend.pipe';
import { GroupPipe } from './pipes/group.pipe';
import { MarkedPipe } from './pipes/marked.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { SearchPipe } from './pipes/search.pipe';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { QRCodeModule } from 'angularx-qrcode';
import { AuthInterceptor } from './services/auth.interceptor';
import { Camera } from '@ionic-native/camera/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
// import { FileTransfer, FileUploadOptions, FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { File, FileEntry } from '@ionic-native/file/ngx';
import { FilePath } from '@ionic-native/file-path/ngx';
import { SharedModule } from './services/share.module';
import { AccordionComponent } from './components/accordion/accordion.component';
import { CommonModule } from '@angular/common';
//import { FCM } from '@ionic-native/fcm/ngx';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
// import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { Crop } from '@ionic-native/crop/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
// import { Base64 } from '@ionic-native/base64/ngx';
import { FileChooser } from '@ionic-native/file-chooser/ngx';
import { CameraPreview } from '@ionic-native/camera-preview/ngx';
import { Device } from '@ionic-native/device/ngx';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { Chooser } from '@ionic-native/chooser/ngx';
import { HTTP } from '@ionic-native/http/ngx';
// import { ZBar } from '@ionic-native/zbar/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { Downloader } from '@ionic-native/downloader/ngx';
import { Base64ToGallery } from '@ionic-native/base64-to-gallery/ngx';
import { IOSFilePicker } from '@ionic-native/file-picker/ngx';
import { DocumentPicker } from '@ionic-native/document-picker/ngx';

import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
// import { LongPressDirective } from './directives/long-press.directive';
import { Badge } from '@ionic-native/badge/ngx';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    // IonicModule.forRoot(MyApp, {
    //   scrollAssist: false,
    //   autoFocusAssist: false
    //   })
    IonicModule.forRoot({
      mode: 'ios',
      backButtonIcon: 'caret-back',
      backButtonText: '戻る'
    }) /**アプリをiOS仕様に設定 */,
    // IonicStorageModule.forRoot(),
    IonicStorageModule.forRoot({
      name: '_metalive'
      // driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    // IonicStorageModule.forRoot({
    //   name: '_metalive',
    //   driverOrder: ['indexeddb', 'sqlite', 'websql','localstorage'],

    // }),
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production
    }),
    QRCodeModule
  ],
  declarations: [AppComponent], //, LongPressDirective],// ConversationPipe, DateFormatPipe, FriendPipe, GroupPipe, MarkedPipe, SafePipe, SearchPipe],
  providers: [
    //   {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthInterceptor,
    //   multi: true
    // },
    Camera,
    CameraPreview,
    MediaCapture,
    InAppBrowser,
    // FileTransfer,
    File,
    FileChooser,
    FilePath,
    Chooser,
    DocumentPicker,
    // Base64,
    // ZBar,
    BarcodeScanner,
    Downloader,
    Base64ToGallery,
    IOSFilePicker,
    Crop,
    Device,
    FCM,
    Keyboard,
    HTTP,
    InAppBrowser,
    SplashScreen,
    StatusBar,
    Clipboard,
    SocialSharing,
    PreviewAnyFile,
    AndroidPermissions,
    // InAppPurchase2,
    AppVersion,
    Market,
    NgxQRCodeModule,
    Badge,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
