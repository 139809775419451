import { NgModule } from '@angular/core';
import { FriendPipe } from './../pipes/friend.pipe';
import { ConversationPipe } from './../pipes/conversation.pipe';
import { GroupPipe } from './../pipes/group.pipe';
import { SearchPipe } from './../pipes/search.pipe';
import { DateFormatPipe } from './../pipes/date.pipe';
import { SafePipe } from '../pipes/safe.pipe';
import { MarkedPipe } from '../pipes/marked.pipe';
import { GetTextPipe } from '../pipes/get-text.pipe';


@NgModule({
  imports: [],
  declarations: [
    FriendPipe,
    ConversationPipe,
    GroupPipe,
    SearchPipe,
    DateFormatPipe,
    SafePipe,
    MarkedPipe,
    GetTextPipe,

  ],
  exports: [FriendPipe,
    ConversationPipe,
    GroupPipe,
    SearchPipe,
    DateFormatPipe,
    SafePipe,
    MarkedPipe,
    GetTextPipe,

  ]
})
export class SharedModule {}
