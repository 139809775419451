import { Injectable } from '@angular/core';
//import { Storage } from '@ionic/storage';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(public storage: Storage) {}

  // public async createStorage() {
  //   await this.storage.create();
  //
  // }
  public async create() {
    await this.storage.create();

  }

  public set(settingName, value) {
    return this.storage.set(`setting:${settingName}`, value);
  }
  public async get(settingName) {
    return await this.storage.get(`setting:${settingName}`);
  }
  public async remove(settingName) {
    return await this.storage.remove(`setting:${settingName}`);
  }
  public async keys(){
    return await this.storage.keys();
  }
  public clear() {
    this.storage.clear().then(() => {
      console.log('all keys cleared');
    });
  }
}
