import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Estimate } from '../models/estimate';
import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class EstimateService {
  AUTH_SERVER: string = environment.api_url;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer '
    })
  };

  userProfile: any;
  clients: any;
  clientsFromServerDone: boolean = false;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private apiService: ApiService
  ) {}

  getAll() {
    return this.http.get<Estimate[]>(`${this.AUTH_SERVER}/estimateList`);
  }

  getAllKbn() {
    return this.http.get<any[]>(`${this.AUTH_SERVER}/common/get_kbn`);
  }

  entry(token, EstimateInfo: Estimate): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.post<Estimate>(
      `${this.AUTH_SERVER}/estimate/store`,
      EstimateInfo,
      this.httpOptions
    );
  }

  async getClients(): Promise<void> {
    await this.storageService.get('currentUser').then(async (value) => {
      this.userProfile = JSON.parse(value);
      await this.storageService.get('accessToken').then(async (val) => {
        let token = val;
        await this.storageService.get('clientList').then(async (clientList) => {
          if (clientList === null || undefined) {
            await this.apiService
              .getClientsList(token, this.userProfile)
              .subscribe(async (clients) => {
                if (clients.length === 0) {
                  await this.storageService.set('clientList', undefined);
                  return;
                }
                if (
                  clients.result.member_list === null ||
                  clients.result.member_list === undefined
                ) {
                  await this.storageService.set('clientList', undefined);
                  return;
                }
                if (
                  clients.result.member_list !== null ||
                  clients.result.member_list !== undefined
                ) {
                  let myClients = clients.result.member_list.map((el) => {
                    let newList = Object.assign({}, el);
                    newList.combined = `${el.last_name} ${el.last_name_kana} ${el.first_name} ${el.first_name_kana} ${el.company_name} ${el.company_name_kana}`;
                    return newList;
                  });
                  await this.storageService.set('clientList', myClients);
                  return;
                }
              });
          }
        });
      });
    });
  }
}
