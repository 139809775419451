import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/user';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { Platform } from '@ionic/angular';
import { StorageService } from './storage.service';
import { ReplaySubject } from 'rxjs';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject, of } from 'rxjs';
import { LoadingService } from './loading.service';

const TOKEN_KEY = 'accessToken';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  currentUserFromStorage: any;
  authState = new BehaviorSubject(false);
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  token = '';
  AUTH_SERVER: string = environment.api_url;

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer '
    })
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private tokenService: TokenService,
    private platform: Platform,
    private storageService: StorageService,
    private loadingService: LoadingService
  ) {
    this.createStorage();
    this.platform.ready().then(() => {});
  }
  async createStorage() {
    await this.storageService.create().then((result) => {
      console.log('db is now set and the rest should be work');
      this.loadToken();
    });
  }
  async setCurrentUser() {
    await this.storageService.get('currentUser').then((val) => {
      console.log('Your currentUser is', val);
      this.currentUserFromStorage = val;
      console.log('load complete');
    });
    if (this.currentUserFromStorage !== null) {
      console.log('1 currentuser from storage');
      this.currentUserSubject = new BehaviorSubject<User>(
        JSON.parse(this.currentUserFromStorage)
      );
      console.log('2 set behavior object');
      this.changeIsAuthenticated(true);
      console.log('change to true');

      return (this.currentUser = this.currentUserSubject.asObservable());
    } else {
      this.changeIsAuthenticated(false);
    }
  }

  changeIsAuthenticated(value) {
    return this.isAuthenticated.next(value);
  }

  async loadToken() {
    const token = await this.storageService.get('accessToken');
    const userObject = await this.storageService.get('currentUser');
    if (token && token.value) {
      console.log('set token: ', token.value);
      this.token = token.value;
      this.isAuthenticated.next(true);
      this.setCurrentUser();
    } else {
      this.isAuthenticated.next(false);
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  getCurrentUser() {
    if (this.currentUserSubject !== undefined) {
      return this.currentUserSubject.value;
    } else {
      return null;
    }
  }
  forgetPassword(passwordInfo): Observable<any> {
    return this.http.post<any>(
      `${this.AUTH_SERVER}/auth/forget_password`,
      passwordInfo
    );
  }

  register(userInfo: User): Observable<any> {
    return this.http
      .post<User>(`${this.AUTH_SERVER}/auth/register`, userInfo)
      .pipe(catchError(this.handleError<any>('error')));
  }

  sendMail(email: any) {
    return this.http.post<User>(`${this.AUTH_SERVER}/auth/send_mail`, email);
  }

  login(userInfo: User) {
    console.log('userinfo', userInfo);
    return this.http
      .post<any>(`${this.AUTH_SERVER}/auth/login`, userInfo)
      .pipe(
        map((res: any) => {
          if (res.result.token !== undefined) {
            this.storageService.set(
              'accessToken',
              JSON.stringify(res.result.token.original.access_token).slice(
                1,
                -1
              )
            );

            console.log(
              'user data from server',
              res.result.token.original.user
            );

            this.storageService.set('isLoggedInWithBackend', 'true');
            this.storageService.set('isLoggedInWithFirebase', 'true');
            return res;
          } else {
            return res;
          }
        })
      )
      .pipe(catchError(this.handleError<any>('error')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.error(error.error);
      let result = error.error;
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  logoutFromServer(userInfo: User, token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.post<User>(
      `${this.AUTH_SERVER}/auth/logout`,
      userInfo,
      this.httpOptions
    );
  }
  async logout(userInfo: User, page) {
    // remove user from local storage to log user out
    console.log('page', page);
    await this.storageService.get('accessToken').then(async (val) => {
      console.log('Your accessToken is', val);
      let token = val;
      if (userInfo != null || userInfo != undefined) {
        this.savePushToken(token, '-', 0).subscribe((res) => {
          console.log(res);
        });
        await this.logoutFromServer(userInfo, val).subscribe((res) => {
          console.log('logout done???', res);
        });
      }
      if (this.platform.is('cordova')) {
        // this.firebaseService
        //   .updateUser({
        //     isPushEnabled: false,
        //     pushToken: '',
        //     userId: userInfo.firebase_id
        //   })
        //   .then((result) => {
        //     console.log('result', result);
        //   });
      }
    });

    if (this.currentUserSubject !== undefined) {
      this.currentUserSubject.next(null);
    }
    await this.storageService.get('isLoggedIn').then((data) => {
      console.log('login status 1', data);
    });
    this.isAuthenticated.next(false);
    await this.storageService.clear();
    if (page === 'twoUsersLoggedAtSameTime') {
      this.storageService.set('twoUsersLoggedAtSameTime', true);
      this.loadingService.showToast(
        '2人の違うユーザーは同時にログインできないため、セキュリティーのため、ログアウトしました。'
      );
    }
    // await this.storageService.get('isLoggedIn').then((data) => {
    //   console.log('login status 1', data);
    // });

    // this.storageService.get('currentUser').then((val) => {
    //   console.log('Your currentUser after logout', val);
    // });
    console.log('page after get the current user', page);
    if (page === 'thanks') {
      this.router.navigateByUrl('/thanks', { replaceUrl: true });
    } else if (page === 'forgot-password') {
      this.router.navigateByUrl('/forgot-password', { replaceUrl: true });
    } else {
      this.router.navigateByUrl('/', { replaceUrl: true });
    }
    // if ( (page !== 'forgot-password')) {
    //   this.router.navigateByUrl('/', { replaceUrl: true });
    // }
    // return this.http.post<any>(`${this.AUTH_SERVER}/logout`, userInfo).pipe(
    //   map((user) => {
    //     console.log('current user after logout:', localStorage.getItem('currentUser'))
    //     localStorage.removeItem('currentUser');
    //     console.log('currentUser after logout 2', localStorage.getItem('currentUser'))
    //     this.currentUserSubject.next(null);
    //     this.tokenService.removeToken();
    //     this.router.navigate(['']);

    //     return user;
    //   })
    // );
    //    return null;
    return true;
  }

  savePushToken(token, pushToken, status) {
    console.log('save push token', token);
    console.log('pushToken', pushToken);
    let userObject; //= this.authService.getCurrentUser();
    //userObject.push_message_id = pushToken;
    // return this.updateUser(token, userObject);
    let push = {
      push_message_id: pushToken,
      push_message_status: status //0 is false -> no push message string, 1 is true -> send token
    };
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.post<any>(
      `${this.AUTH_SERVER}/auth/update_push_id`,
      push,
      this.httpOptions
    );
  }

  async maintenanceLogout(page) {
    // remove user from local storage to log user out
    console.log('page', page);

    await this.storageService.get('isLoggedIn').then((data) => {
      console.log('login status 1', data);
    });
    console.log('page after get the current user', page);
    if (page === 'maintenance') {
      this.router.navigateByUrl('/maintenance', { replaceUrl: true });
    } else if (page === 'thanks') {
      this.router.navigateByUrl('/thanks', { replaceUrl: true });
    } else if (page === 'forgot-password') {
      this.router.navigateByUrl('/forgot-password', { replaceUrl: true });
    } else {
      this.router.navigateByUrl('/', { replaceUrl: true });
    }
    return true;
  }

  profileUser(token): Observable<any> {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + token
      })
    };
    return this.http.get(
      `${this.AUTH_SERVER}/auth/user_profile`,
      this.httpOptions
    );
  }

  updateUser(userInfo: User): Observable<User> {
    console.log('test here for update user route???', userInfo);
    return this.http.post<User>(
      `${this.AUTH_SERVER}/auth/update_user`,
      userInfo
    );
  }

  updateFCMToken(token) {
    let userObject = this.currentUserValue;
    userObject.push_message_id = token;
    return this.updateUser(userObject);
  }

  updateFirebaseId(userInfo: User): Observable<any> {
    console.log('FirebaseId updated', userInfo);
    return this.http.post<any>(
      `${this.AUTH_SERVER}/auth/update_firebase_id`,
      userInfo
    );
  }
}
