import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetTextService } from '../services/get-text.service';

@Pipe({
  name: 'text'
})
export class GetTextPipe implements PipeTransform {
  constructor(private getTextService: GetTextService) {}

  transform(key: string): Observable<string> {
    return this.getTextService.valueChanges.pipe<string>(
      map((texts) => {
        if (!(key in texts)) {
          console.warn(`GetTextService: Missing text for ${key}.`);
          return key;
        }

        return texts[key];
      })
    );
  }
}
