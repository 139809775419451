import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad {
  constructor(private router: Router) {}

  canLoad() {
    // return this.storage.get('ion_did_tutorial').then(res => {
    //   if (res) {
    //     this.router.navigate(['/app', 'tabs', 'tabs1']);
    //     return false;
    //   } else {
    //     return true;
    //   }
    // });
    return true
  }
}
